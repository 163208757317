const initSegment = () => {
  // Create a queue, but don't obliterate an existing one!
  const analytics = ((window as any).analytics =
    (window as any).analytics || [])
  // If the real analytics.js is already on the page return.
  if (analytics.initialize) {
    return
  }
  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error('Segment snippet included twice.')
    }
    return
  }
  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true
  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'page',
    'once',
    'off',
    'on',
  ]
  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.

  analytics.factory = (method) =>
    function () {
      const args = Array.prototype.slice.call(arguments)
      args.unshift(method)
      try {
        analytics.push(args)
      } catch (error) {}
      return analytics
    }

  // For each of our methods, generate a queueing stub.
  let i = 0
  while (i < analytics.methods.length) {
    const key = analytics.methods[i]
    analytics[key] = analytics.factory(key)
    i++
  }
  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.

  analytics.load = function (key) {
    // Create an async script element based on your key.
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = `${document.location.protocol}//cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`
    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0]
    first.parentNode.insertBefore(script, first)
  }

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '3.1.0'
  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  const analyticsKeysRaw = segmentsGuard(
    segmentsGuard(
      document.querySelector("meta[name='analytics']"),
      (x1) => x1.dataset
    ),
    (x) => x.analytics
  )
  const analyticsKeys = analyticsKeysRaw && JSON.parse(analyticsKeysRaw)
  const segmentApiKey = segmentsGuard(
    analyticsKeys != null ? analyticsKeys['Segment.io'] : undefined,
    (x2) => x2.apiKey
  )
  if (segmentApiKey) {
    analytics.load(segmentApiKey)
  }
  // Make the first page call to load the integrations. If
  // you'd like to manually name or tag the page, edit or
  // move this call however you'd like.
}
document.addEventListener('DOMContentLoaded', initSegment)
// ---
// generated by js2coffee 2.1.0

function segmentsGuard(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined
}
