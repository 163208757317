import { get } from 'lodash'
import APP_CONFIG from 'src/common/app-config'
import { getItem } from 'src/util/cookies'

type IrisMetadata = Record<string, any>

/*
 * This wrapper for Iris.emit() can be used in /app or /client
 * so long as Iris has been initialized (chances are it has)
 */
export const emit = <T = IrisMetadata>(event: string, meta?: T): void => {
  const tracker = window && window.iris

  if (!tracker) {
    console.error('Iris is not supported')
    return
  }

  let configData = {}
  if (!!APP_CONFIG) {
    let role = ''
    if (get(APP_CONFIG, 'user.is_owner', false)) {
      role = 'owner'
    } else if (get(APP_CONFIG, 'user.is_author', false)) {
      role = 'author'
    } else if (get(APP_CONFIG, 'user.is_affiliate', false)) {
      role = 'affiliate'
    }
    configData = {
      plan_id: get(APP_CONFIG, 'school.school_plan_id', null),
      plan_name: get(APP_CONFIG, 'school.plan_name', null),
      plan_term: get(APP_CONFIG, 'school.school_plan.interval', null),
      school_name: get(APP_CONFIG, 'school.name', null),
      school_id: get(APP_CONFIG, 'school._id', null),
      teachable_account_id: get(APP_CONFIG, 'user.teachable_account_id', null),
      user_id: get(APP_CONFIG, 'user._id', null),
      user_role: role,
    }
  }

  const defaultData = {
    url: location && location.href,
    session_id: getItem('ahoy_visitor'),
  }

  if (tracker && typeof tracker.emit === 'function') {
    tracker.emit(event, {
      ...defaultData,
      ...configData,
      ...meta,
    })
  }
}
