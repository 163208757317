const LECTURE_SECTION_SELECTOR = '.block__curriculum__section'
const VIEW_ALL_LECTURES_SELECTOR = '.block__curriculum__view-all-lectures-btn'
const MAX_VISIBLE_SECTIONS = 3

export const curriculumBlock = () => {
  const proto = {
    init(element: Element) {
      this.element = element
      this.sections = this.element.querySelectorAll(LECTURE_SECTION_SELECTOR)

      this.toggleAllSections = this.toggleAllSections.bind(this)
      this.setupPreview = this.setupPreview.bind(this)

      if (this.sections.length > MAX_VISIBLE_SECTIONS) {
        this.setupPreview()
      }
    },

    setupPreview() {
      this.element.dataset.expanded = 'true'
      this.viewAllButton = this.element.querySelector(
        VIEW_ALL_LECTURES_SELECTOR
      )

      this.viewAllButton.addEventListener('click', this.toggleAllSections)
      this.toggleAllSections()
    },

    toggleAllSections() {
      const { expanded } = this.element.dataset

      if (expanded === 'true') {
        this.element.dataset.expanded = 'false'
        this.element.classList.remove('expanded')
        this.sections.forEach((item: Element, index: number) => {
          if (index === MAX_VISIBLE_SECTIONS - 1) {
            item.classList.add('last-visible')
          }

          if (index >= MAX_VISIBLE_SECTIONS) {
            item.classList.add('hidden')
          }
        })
      } else {
        this.element.dataset.expanded = 'true'
        this.element.classList.add('expanded')
        this.sections.forEach((item: Element) => {
          item.classList.remove('hidden')
          item.classList.remove('last-visible')
        })
      }
    },
  }

  return Object.create(proto)
}
