// types from from https://github.com/pimterry/typesafe-get/blob/master/index.ts
import get from 'lodash/get'

// Given an object T - returns a type with D/null removed from
// the types of any fields.
type Defined<T> = Exclude<Exclude<T, null>, undefined>
// The value of T[S], if it's not null/D
type Prop<T, S extends keyof T> = Defined<T[S]>

type DefaultIfUndefined<V, D> = Exclude<V, undefined> | D

export function tGet<
  T,
  S1 extends keyof Defined<T>,
  S2 extends keyof Prop<Defined<T>, S1>,
  S3 extends keyof Prop<Prop<Defined<T>, S1>, S2>,
  S4 extends keyof Prop<Prop<Prop<Defined<T>, S1>, S2>, S3>,
  S5 extends keyof Prop<Prop<Prop<Prop<Defined<T>, S1>, S2>, S3>, S4>,
  D = undefined
>(
  obj: T,
  props: [S1, S2, S3, S4, S5],
  defaultValue?: D
): DefaultIfUndefined<Prop<Prop<Prop<Prop<Defined<T>, S1>, S2>, S3>, S4>[S5], D>

export function tGet<
  T,
  S1 extends keyof Defined<T>,
  S2 extends keyof Prop<Defined<T>, S1>,
  S3 extends keyof Prop<Prop<Defined<T>, S1>, S2>,
  S4 extends keyof Prop<Prop<Prop<Defined<T>, S1>, S2>, S3>,
  D = undefined
>(
  obj: T,
  props: [S1, S2, S3, S4],
  defaultValue?: D
): DefaultIfUndefined<Prop<Prop<Prop<Defined<T>, S1>, S2>, S3>[S4], D>

export function tGet<
  T,
  S1 extends keyof Defined<T>,
  S2 extends keyof Prop<Defined<T>, S1>,
  S3 extends keyof Prop<Prop<Defined<T>, S1>, S2>,
  D = undefined
>(
  obj: T,
  props: [S1, S2, S3],
  defaultValue?: D
): DefaultIfUndefined<Prop<Prop<Defined<T>, S1>, S2>[S3], D>

export function tGet<
  T,
  S1 extends keyof Defined<T>,
  S2 extends keyof Prop<Defined<T>, S1>,
  D = undefined
>(
  obj: T,
  props: [S1, S2],
  defaultValue?: D
): DefaultIfUndefined<Prop<Defined<T>, S1>[S2], D>

export function tGet<T, S1 extends keyof Defined<T>, D = undefined>(
  obj: T,
  prop1: [S1],
  defaultValue?: D
): DefaultIfUndefined<Defined<T>[S1], D>

export function tGet<T, S1 extends keyof Defined<T>, D = undefined>(
  obj: T,
  prop1: S1,
  defaultValue?: D
): DefaultIfUndefined<Defined<T>[S1], D>

export function tGet<T, D = undefined>(
  obj: T,
  props: string[] | string,
  defaultValue?: D
): DefaultIfUndefined<any, D> {
  return get(obj, props, defaultValue)
}
