// Create a dummy analytics object until real loaded
let method
;(window as any).fedoraAnalytics || ((window as any).fedoraAnalytics = [])
;(window as any).fedoraAnalytics.methods = [
  'identify',
  'track',
  'trackLink',
  'trackForm',
  'trackClick',
  'trackSubmit',
  'page',
  'pageview',
  'ab',
  'alias',
  'ready',
  'group',
  'on',
  'once',
  'off',
]
;(window as any).fedoraAnalytics.factory = (method) =>
  function () {
    const args = Array.prototype.slice.call(arguments)
    args.unshift(method)
    ;(window as any).fedoraAnalytics.push(args)
    return (window as any).fedoraAnalytics
  }

let i = 0
while (i < (window as any).fedoraAnalytics.methods.length) {
  method = (window as any).fedoraAnalytics.methods[i]
  ;(window as any).fedoraAnalytics[
    method
  ] = (window as any).fedoraAnalytics.factory(method)
  i++
}
// Load analytics async

;(window as any).fedoraAnalytics.load = function (callback) {
  if (document.getElementById('analytics-js')) {
    return
  }
  // We make a copy if our dummy object
  ;(window as any).a = (window as any).fedoraAnalytics
  const script = document.createElement('script')
  script.async = true
  script.id = 'analytics-js'
  script.type = 'text/javascript'
  script.src = '/analytics.js'
  script.addEventListener(
    'load',
    (e) => {
      if (typeof callback === 'function') {
        callback(e)
      }
    },
    false
  )
  const first = document.getElementsByTagName('script')[0]
  if (first && first.parentNode) {
    first.parentNode.insertBefore(script, first)
  }
}

// Events we send to our own (Teachable) Google Analytics
;(window as any).trackTeachableGAEvent = function (name, properties) {
  const eventProperties = analyticsConfigGuard(
    analyticsEvents()[0],
    (x) => x.properties
  )
  const courseName =
    (eventProperties != null ? eventProperties.name : undefined) ||
    analyticsConfigGuard(
      eventProperties != null ? eventProperties.products : undefined,
      (x1) => x1[0].name
    )
  const currency =
    eventProperties != null ? eventProperties.currency : undefined
  switch (name) {
    case 'Added Product':
      ;(window as any).trackTeachableGAEvent('Completed Checkout Step', {
        name: 'Completed Checkout Step',
        step: 1,
      })
      try {
        ;(window as any).ga('fedoraTracker.ec:addProduct', properties)
        ;(window as any).ga('fedoraTracker.ec:setAction', 'add')
      } catch (error) {}
      break
    case 'Completed Checkout Step':
      try {
        ;(window as any).ga(
          'fedoraTracker.ec:setAction',
          'checkout',
          properties
        )
      } catch (error1) {}
      break
    case 'Completed Order':
      const product = analyticsConfigGuard(
        eventProperties != null ? eventProperties.products : undefined,
        (x2) => x2[0]
      )
      const sale = {
        id: eventProperties != null ? eventProperties.orderId : undefined,
        revenue: eventProperties != null ? eventProperties.total : undefined,
        coupon: eventProperties != null ? eventProperties.coupon : undefined,
        tax:
          eventProperties != null ? eventProperties.net_tax_charge : undefined,
      }
      try {
        ;(window as any).ga('fedoraTracker.set', 'currencyCode', currency)
        ;(window as any).ga('fedoraTracker.ec:addProduct', product)
        ;(window as any).ga('fedoraTracker.ec:setAction', 'purchase', sale)
        ;(window as any).ga(
          'fedoraTracker.send',
          'event',
          'EnhancedEcommerce',
          'Completed Order',
          { nonInteraction: 1 }
        )
      } catch (error2) {}
      break
  }
  try {
    ;(window as any).ga('fedoraTracker.send', {
      hitType: 'event',
      eventCategory: courseName,
      eventAction: name,
      eventLabel: name,
    })
  } catch (error3) {}
}
;(window as any).analyticsOptions = function () {
  const options = analyticsConfigGuard(
    document.querySelector("meta[name='analytics']"),
    (x) => x.dataset
  )
  if (typeof options === 'undefined') {
    return { analytics: {}, events: [] }
  }
  return options
}

const analyticsKeys = function () {
  const { analytics } = (window as any).analyticsOptions()
  if (typeof analytics === 'string') {
    return JSON.parse(analytics) || {}
  }
  return {}
}

let analyticsEvents = function () {
  const { events } = (window as any).analyticsOptions()
  return (events && typeof events === 'string' && JSON.parse(events)) || []
}

const trackBackendEvent = function (eventData) {
  if ((window as any).analytics && segmentEnabled()) {
    ;(window as any).analytics.track(eventData.name, eventData.properties)
  }
  ;(window as any).fedoraAnalytics.track(eventData.name, eventData.properties)
  return (window as any).trackTeachableGAEvent(
    eventData.name,
    eventData.properties
  )
}

const trackBackendEvents = function (events) {
  if (!_.isArray(events) || !(events.length > 0)) {
    return
  }

  return _.forEach(events, trackBackendEvent)
}

const currentUser = () =>
  (document.querySelector('#fedora-data') as any).dataset

const trackUser = function (currentUser) {
  if (!(currentUser != null ? currentUser.id : undefined)) {
    return
  }
  const userInfo = {
    name: currentUser.name,
    userId: currentUser.id,
    role: currentUser.role,
    email: currentUser.email,
    createdAt: currentUser.joinedAt && currentUser.joinedAt.replace(/"/g, ''),
    avatar: currentUser.gravatarUrl,
  }
  try {
    ;(window as any).fedoraAnalytics.identify(currentUser.id, userInfo)
    if ((window as any).analytics && segmentEnabled()) {
      ;(window as any).analytics.identify(currentUser.id, userInfo)
    }
  } catch (error) {}
}

let segmentEnabled = () =>
  analyticsConfigGuard(analyticsKeys()['Segment.io'], (x) => x.apiKey)

const trackPage = function () {
  const pageName = document.title
  const settings = {
    location: window.location.href,
    path: window.location.pathname,
  }
  // Send to segment if set
  if ((window as any).analytics && segmentEnabled()) {
    ;(window as any).analytics.page(pageName, settings)
  }
  // send to analytics.js
  ;(window as any).fedoraAnalytics.page(pageName, settings)
}
;(window as any).trackEvent = function (eventName, dta) {
  let data = dta
  if (data == null) {
    data = {}
  }
  const { schoolPreferences } = currentUser()
  const parsedSchoolPrefs = schoolPreferences && JSON.parse(schoolPreferences)
  const shouldNotSendEvents =
    parsedSchoolPrefs.disable_js_course_event_tracking === 'true'

  if (shouldNotSendEvents) {
    return
  }

  try {
    if (segmentEnabled()) {
      const analyticsTrackBegin = Date.now()
      const analyticsTrackInterval = setInterval(() => {
        if (
          (window as any).analytics != null
            ? (window as any).analytics.track
            : undefined
        ) {
          clearInterval(analyticsTrackInterval)

          return (window as any).analytics.track(eventName, data)
        }

        if (Date.now() - analyticsTrackBegin > 2000) {
          // tslint:disable-next-line:no-console
          return console.log(
            `failed to track ${eventName}, adblock may be enabled`
          )
        }
      }, 10)
    } else {
      ;(window as any).fedoraAnalytics.track(eventName, data)
    }
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.log(`failed to track ${eventName}, adblock may be enabled`)
  }
}
;(window as any).fedoraAnalytics.load(() => {
  const keys = analyticsKeys()
  if (keys != null) {
    delete keys['Segment.io']
  }
  ;(window as any).fedoraAnalytics.initialize(keys)
  while ((window as any).a.length > 0) {
    const item = (window as any).a.shift()
    method = item.shift()
    if ((window as any).fedoraAnalytics[method]) {
      ;(window as any).fedoraAnalytics[method].apply(
        (window as any).fedoraAnalytics,
        item
      )
    }
  }
})

const initAnalytics = function () {
  publishPageAnalyticsEvents()
}
let publishPageAnalyticsEvents = function () {
  const backendEvents = analyticsEvents()
  const user = currentUser()
  trackUser(user)
  trackPage()
  return trackBackendEvents(backendEvents)
}

document.addEventListener('ready', initAnalytics)
document.addEventListener('DOMContentLoaded', initAnalytics)

function analyticsConfigGuard(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined
}
