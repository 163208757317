/*
  strategyMap{}
    is an object of functions where the keys denote which attribute the function should be applied on

  createStrategyTransformer(strategyMap)
    returns a strategyTransform()

  strategyTransform(target) // mutates target
    is the function that takes a target object and applies the strategyMap on it.
*/

type strategyMap = {
  [key: string]: (value: any) => any
}

export function createStrategyTransformer(strategyMap: strategyMap) {
  return function strategyTransform<T>(target: T): { [key in keyof T]: any } {
    Object.entries(strategyMap).forEach(applyStrategy)
    return target

    function applyStrategy([key, strategy]) {
      if (typeof strategy === 'function') target[key] = strategy(target[key])
      else console.warn(`${key} should be a function`)
    }
  }
}
