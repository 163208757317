import { isPlainObject } from 'lodash'

export function flatten(
  obj: Record<string, any>,
  { nestKeys = false } = {}
): Record<string, any> {
  const visited = new Map()
  return recursiveFlatten(obj)

  function recursiveFlatten(obj) {
    if (visited.has(obj)) return {}
    visited.set(obj, true)
    return Object.entries(obj).reduce((result, [key, value]) => {
      if (isPlainObject(value)) {
        Object.entries(recursiveFlatten(obj[key])).forEach(([nKey, nValue]) => {
          result[nestKeys ? `${key}.${nKey}` : nKey] = nValue
        })
      } else {
        result[key] = value
      }
      return result
    }, {})
  }
}
