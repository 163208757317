import fetch from './fetch'

let csrfToken

/**
 * Takes in any URL and adds a .json extension if it's not already there.
 *
 * @param   {String} _endpoint
 * @returns {String}
 */
function formatJsonEndpoint(_endpoint) {
  // Don't mutate arguments, it's slow.
  let endpoint = `${_endpoint}`

  const hasJsonExtension = endpoint.indexOf('.json') > -1

  if (!hasJsonExtension) {
    if (endpoint.indexOf('?') > -1) {
      const splitUrl = endpoint.split('?')

      endpoint = `${splitUrl[0]}.json?${splitUrl[1]}`
    } else if (endpoint.indexOf('#') > -1) {
      endpoint = `${endpoint.split('#')[0]}.json`
    } else {
      endpoint = `${endpoint}.json`
    }
  }

  if (endpoint.charAt(endpoint.length - 1) !== '#') {
    endpoint += '#'
  }

  return `${endpoint}${Date.now()}`
}

/**
 * Gets the csrf token for you.
 *
 * @returns {String}
 */
function getCsrfToken() {
  if (!csrfToken) {
    const $csrf = document.querySelector('[name="csrf-token"]')

    if ($csrf) {
      csrfToken = $csrf.getAttribute('content')
    } else {
      csrfToken = ''
    }
  }

  return csrfToken
}

/**
 * Hit internal Teachable API, this method only works for JSON endpoints.
 *
 * @param   {String}  endpoint
 * @param   {Object}  data
 * @returns {Promise}
 */
function api(_endpoint, config = {}) {
  const csrf = getCsrfToken()
  const endpoint = formatJsonEndpoint(_endpoint)
  const headers = new Headers()

  let method = config.method
  if (!method) {
    method = 'GET'
  }

  if (csrf) {
    headers.append('X-CSRF-Token', csrf)
  }

  headers.append('Accept-Encoding', 'gzip, deflate, br')
  headers.append('Accept', 'application/json, text/plain, */*')
  headers.append('Content-Type', 'application/json;charset=UTF-8')

  return new Promise((resolve, reject) => {
    fetch(decodeURIComponent(`${endpoint}`), {
      body: config.data ? JSON.stringify(config.data) : null,
      cache: 'no-store',
      credentials: 'same-origin',
      headers,
      method: method.toUpperCase(),
    })
      .then((res) => {
        if (res.ok) {
          if (method === 'DELETE') {
            return null
          }

          return res.json()
        }

        const errorObj = new Error(res.statusText)

        return res
          .json()
          .then((jsonRes) => {
            errorObj.response = jsonRes
            return errorObj
          })
          .catch(() => {
            errorObj.response = res
            return errorObj
          })
      })
      .then((data) => {
        if (!data) {
          return resolve()
        }

        if (data instanceof Error) {
          if (config.throwErrorObj) {
            return reject(data)
          }
          return reject(data.message)
        }

        return resolve(data)
      })
  })
}

export default api
