/**
 * @file
 * Pulls in a fetch polyfill, also adds cookies and CSRF token to your requests.
 * This method should only be used for Teachable API calls, not calls to
 * third-party APIs. For that, just use the fetch polyfill imported below.
 */
import 'isomorphic-fetch'

/**
 * The public fetch function. Use this instead of window.fetch if you need to
 * make authenticated requests.
 *
 * @param   {String}
 * @param   {Object}
 * @returns {Promise}
 */
export default (url, _config = {}) => {
  const config = { ..._config }

  if (!url) {
    return false
  }

  if (config.method === 'GET' && 'body' in config) {
    delete config.body
  }

  return fetch(url, config)
}
