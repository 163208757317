import { CouponProductsType, CouponType } from 'student/student/types'

const FEDORA_META_ELEMENT_SELECTOR = '#fedora-data'
const PRICING_PLAN_DESCRIPTION_CONTAINER_SELECTOR =
  '.block__pricing__plan__description__container'
const PRICING_PLAN_DESCRIPTION_SELECTOR = '.block__pricing__plan__description'
const SHOW_DESCRIPTION_BUTTON_SELECTOR =
  '.block__pricing__plan__description__show-button'
const SHOW_DESCRIPTION_BUTTON_TEXT_SELECTOR =
  '.block__pricing__plan__description__show-button__text'
const PRICING_PLAN_PRICE_SELECTOR = '.block__pricing__plan__price'
const PRICING_PLAN_RADIO_SELECTOR = 'input[name="product_id"]'

export const pricingBlock = () => {
  const proto = {
    init(element: HTMLElement) {
      this.element = element
      this.fedoraDataElement = document.querySelector(
        FEDORA_META_ELEMENT_SELECTOR
      )

      this.applyCouponCode = this.applyCouponCode.bind(this)
      this.updatePricingDisplay = this.updatePricingDisplay.bind(this)
      this.toggleDescriptionText = this.toggleDescriptionText.bind(this)
      this.setupDescriptionText = this.setupDescriptionText.bind(this)
      this.setupPricingPlanDisplay = this.setupPricingPlanDisplay.bind(this)
      this.toggleAutoexpandText = this.toggleAutoexpandText.bind(this)

      this.setupDescriptionText()
      this.setupPricingPlanDisplay()
    },

    setupDescriptionText() {
      const pricingPlanDescriptionEls = this.element.querySelectorAll(
        PRICING_PLAN_DESCRIPTION_CONTAINER_SELECTOR
      )

      pricingPlanDescriptionEls.forEach((el: HTMLElement) => {
        const button = el.querySelector(SHOW_DESCRIPTION_BUTTON_SELECTOR)
        const textEl = el.querySelector(PRICING_PLAN_DESCRIPTION_SELECTOR)
        el.dataset.collapsed = 'true'

        if (textEl) {
          textEl.classList.add('hidden')
        }

        if (button) {
          button.addEventListener('click', (e: MouseEvent) =>
            this.toggleDescriptionText(e.currentTarget as HTMLInputElement)
          )
        }
      })
    },

    setupPricingPlanDisplay() {
      const pricingPlanRadios = this.element.querySelectorAll(
        PRICING_PLAN_RADIO_SELECTOR
      )

      pricingPlanRadios.forEach((el: HTMLElement, index: number) => {
        el.addEventListener('change', (e: MouseEvent) =>
          this.toggleAutoexpandText(e.currentTarget as HTMLInputElement)
        )

        if (index === 0) {
          this.toggleAutoexpandText(el)
        }
      })
    },

    applyCouponCode(data: CouponProductsType) {
      const couponInput = document.createElement('input')
      couponInput.type = 'hidden'
      couponInput.name = 'coupon_code'
      couponInput.value = data.coupon_code

      this.element.insertBefore(couponInput, this.element.firstChild)

      data.discounted_products.forEach((product) => {
        const el = this.element.querySelector(
          `[data-id='${product.product_id}']`
        )

        if (el) {
          this.updatePricingDisplay(el, product)
        }
      })

      const allProducts = this.element.querySelectorAll(
        '.block__pricing__plan[data-id]'
      )
      const matchingProductIndices = data.discounted_products
        .map((product) => {
          return Array.from(allProducts).findIndex(
            (el) =>
              (el as HTMLElement).dataset.id === product.product_id.toString()
          )
        })
        // All of the codes we got should match a product, but just in case..
        .filter((x) => x >= 0)
      if (matchingProductIndices.length) {
        const firstProduct = allProducts[Math.min(...matchingProductIndices)]
        const radioEl = firstProduct.querySelector(
          PRICING_PLAN_RADIO_SELECTOR
        ) as HTMLInputElement
        if (radioEl) {
          radioEl.checked = true
        }
      }
    },

    updatePricingDisplay(el: HTMLElement, discount: CouponType) {
      const priceEl = el.querySelector(PRICING_PLAN_PRICE_SELECTOR)

      if (priceEl) {
        priceEl.innerHTML = `<span class="block__pricing__plan__price--final">${discount.formatted_final_price}</span>
        <span class="block__pricing__plan__price--original">${discount.original_price}</span>`
      }
    },

    toggleDescriptionText(button?: HTMLElement, isAutoexpanded?: boolean) {
      if (button) {
        const buttonText = button.querySelector(
          SHOW_DESCRIPTION_BUTTON_TEXT_SELECTOR
        )
        const container = button.parentNode as HTMLElement

        if (container) {
          const text = container.querySelector(
            PRICING_PLAN_DESCRIPTION_SELECTOR
          ) as HTMLElement

          if (container.dataset.collapsed === 'true') {
            container.dataset.collapsed = 'false'
            button.classList.add('expanded')

            if (text) {
              text.classList.remove('hidden')
              text.setAttribute('aria-hidden', 'false')
            }

            if (buttonText) {
              buttonText.innerHTML = 'Less'
            }

            if (isAutoexpanded) {
              button.classList.add('auto_expanded')
            }
          } else if (!isAutoexpanded) {
            container.dataset.collapsed = 'true'
            button.classList.remove('expanded')

            if (text) {
              text.classList.add('hidden')
              text.setAttribute('aria-hidden', 'true')
            }

            if (buttonText) {
              buttonText.innerHTML = 'More'
            }
          }
        }
      }
    },

    toggleAutoexpandText(radio: HTMLInputElement) {
      const pricingPlanRadios = this.element.querySelectorAll(
        PRICING_PLAN_RADIO_SELECTOR
      )

      pricingPlanRadios.forEach((el: HTMLElement) => {
        const pricingContaier = el.parentNode?.parentNode as HTMLElement
        const button = pricingContaier.querySelector(
          SHOW_DESCRIPTION_BUTTON_SELECTOR
        ) as HTMLElement

        if (button?.classList.contains('auto_expanded')) {
          this.toggleDescriptionText(button)
          button.classList.remove('auto_expanded')
        }
      })

      const container = radio.parentNode?.parentNode as HTMLElement

      if (container) {
        const button = container.querySelector(
          SHOW_DESCRIPTION_BUTTON_SELECTOR
        ) as HTMLElement

        this.toggleDescriptionText(button, true)
      }
    },
  }

  return Object.create(proto)
}
