import qs from 'query-string'
import { jsonApi } from 'src/util/api'
import { Logger } from 'student/secure/checkout/sentry'

/*
  Note for our future selves: hello!
  Blocks are cached (app/views/pages/partials/_blocks.liquid)
  so we pull from a VAT endpoint once the page loads & update all the price htmls
*/

type ProductVatInfo = {
  id: number
  attributes: {
    vat_adjusted_price: string
  }
}

const FEDORA_META_ELEMENT_SELECTOR = '#fedora-data'

export function shouldShowVat() {
  const fedoraDataElement: Nullable<HTMLElement> = document.querySelector(
    FEDORA_META_ELEMENT_SELECTOR
  )

  if (!fedoraDataElement) return false

  const { vatCountry } = fedoraDataElement.dataset
  const currentUrl = window.location.pathname

  return (
    vatCountry &&
    vatCountry !== 'null' &&
    currentUrl.indexOf('/purchased') === -1
  )
}

export function getPricingIds(selector) {
  const priceElements: NodeListOf<HTMLElement> = document.querySelectorAll(
    selector
  )

  return Array.from(priceElements).reduce((ids: string[], price) => {
    const id = price.dataset.includeVatPrice
    if (id) {
      ids.push(id)
    }

    return ids
  }, [])
}

export function updatePricesWithVat(
  results: ProductVatInfo[],
  priceSelector,
  disclosureSelector?
) {
  results.forEach((data) => {
    const {
      id,
      attributes: { vat_adjusted_price: vatAdjustedPrice },
    } = data
    const pricingElement = document.querySelectorAll(
      `${priceSelector}[data-include-vat-price="${id}"]`
    )

    pricingElement.forEach((element) => {
      if (vatAdjustedPrice) {
        element.textContent = vatAdjustedPrice
      }
    })

    const disclosureElement = document.querySelectorAll(
      `${disclosureSelector}[data-include-vat-price="${id}"]`
    )
    disclosureElement.forEach((element) => {
      element.classList.remove('hidden')
    })
  })
}

export default async function showVatPrices(
  priceSelector,
  disclosureSelector?
) {
  if (shouldShowVat()) {
    const priceIds = getPricingIds(priceSelector)

    if (priceIds.length === 0) return
    const queryString = qs.stringify(
      { product_ids: priceIds },
      { arrayFormat: 'bracket' }
    )
    const endpoint = `/api/v2/pricing_plans/vat_adjusted_prices?${queryString}`

    try {
      const vatData = await jsonApi<{ data: ProductVatInfo[] }>(endpoint, {
        method: 'GET',
      })
      const results = vatData?.data ?? []

      updatePricesWithVat(results, priceSelector, disclosureSelector)
    } catch (error) {
      Logger.error(error)
    }
  }
}
