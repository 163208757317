import { api } from '@teachable/utils'
import { Logger } from 'student/secure/checkout/sentry'

export const intakeFormBlock = () => {
  const proto = {
    init(form: HTMLFormElement) {
      form.addEventListener('submit', () => this.submitIntakeForm(form))
      this.submitIntakeForm = this.submitIntakeForm.bind(this)
    },

    async submitIntakeForm(form: HTMLFormElement) {
      form.disable = true

      try {
        const id = form.getAttribute('data-id')
        const type = form.getAttribute('data-type')
        const redirectUrl = form.getAttribute('data-redirect-url')
        const inputs: NodeListOf<HTMLInputElement> = form.querySelectorAll(
          '.input'
        )

        const inputData = {}
        if (inputs) {
          inputs.forEach((input) => {
            const label = form.querySelector(`#label-${input.id}`)
            if (label) {
              inputData[label.innerHTML] = input.value
            }
          })
        }
        const emailEl: HTMLInputElement | null = form.querySelector(
          '[name=email]'
        )
        if (emailEl && emailEl.value) {
          const apiEndpoint = `/s/${id}/intake`
          await api(apiEndpoint, {
            method: 'POST',
            data: {
              intake: {
                email: emailEl.value,
                data: inputData,
                intake_type: type,
              },
            },
          })
        }

        if (type !== 'gated' && redirectUrl) {
          window.location.replace(redirectUrl)
        } else {
          const blockId = form.getAttribute('data-block-id')
          const msgEl: HTMLElement | null = document.querySelector(
            `#status-${blockId}`
          )

          if (msgEl) msgEl.hidden = false
          form.hidden = true
        }
      } catch (error) {
        const errorEl = form.querySelector('.block__intake__error')
        if (errorEl) {
          errorEl.innerHTML = 'Something went wrong.  Please try again later.'
        }
        Logger.error(error)
      }

      form.disable = false
    },
  }

  return Object.create(proto)
}
