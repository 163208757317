import '@teachable/icons'
import 'legacyjs/config/analytics'
import 'legacyjs/config/segment'
import { tGet } from 'src/util/objects'
import { queryStringToObject } from 'src/util/query-params'
import api from 'student/shared/util/api'
import onReady from 'student/shared/util/on-ready'
import showVatPrices from 'student/shared/util/vat'
import { CouponProductsType } from 'student/student/types'
import 'student/student/util/analytics-util'
import { courseCards } from './components/course-cards'
import { curriculumBlock } from './components/curriculum-block'
import { emailLeadsFormBlock } from './components/email-leads-form-block'
import { featuredProductsCards } from './components/featured-products-cards'
import { intakeFormBlock } from './components/intake-form-block'
import { pricingBlock } from './components/pricing-block'

const PRICING_PLAN_SELECTOR = '.block__pricing'
const CURRICULUM_SELECTOR = '.block__curriculum'
const FEDORA_DATA_SELECTOR = 'fedora-data'
const COURSE_CARDS_SELECTOR = '.block__cards'
const FEATURED_PRODUCTS_CARDS_SELECTOR = '.block__featured-products__cards'
const INTAKE_FORM_SELECTOR = '.block__intake'
const EMAIL_LEADS_FORM_SELECTOR = '.block__email_leads'
const PRICING_PLAN_PRICE_SELECTOR = '.block__pricing__plan__price'
const FEATURED_PRODUCTS_PRICE_SELECTOR = '.featured-product-card__price'
const PRICING_PLAN_DISCLOSURE_SELECTOR = '.block__pricing__plan__disclosure'

type PricingBlockComponentType = {
  applyCouponCode: (data: CouponProductsType) => void
}

const init = async () => {
  setupPricingBlocks()
  setupCurriculumBlocks()
  setupCourseCards()
  setupFeaturedProductsCards()
  setupIntakeFormBlocks()
  setupEmailLeadsFormBlocks()
}

const setupCurriculumBlocks = () => {
  const curriculumBlockEls = document.querySelectorAll(CURRICULUM_SELECTOR)

  curriculumBlockEls.forEach((el: HTMLElement) => {
    const cb = curriculumBlock()
    cb.init(el)
  })
}

const setupPricingBlocks = async () => {
  const pricingPlanEls = document.querySelectorAll(PRICING_PLAN_SELECTOR)
  const pricingBlocks: PricingBlockComponentType[] = []

  pricingPlanEls.forEach((el: HTMLElement) => {
    const pb = pricingBlock()
    pb.init(el)

    pricingBlocks.push(pb)
  })

  await showVatPrices(
    PRICING_PLAN_PRICE_SELECTOR,
    PRICING_PLAN_DISCLOSURE_SELECTOR
  )
  await applyCouponCodeToPricingBlocks(pricingBlocks)
}

const setupCourseCards = () => {
  const elements = document.querySelectorAll(COURSE_CARDS_SELECTOR) || []

  if (elements.length) {
    const cards = courseCards()
    cards.init()
  }
}

const setupFeaturedProductsCards = () => {
  const elements =
    document.querySelectorAll(FEATURED_PRODUCTS_CARDS_SELECTOR) || []

  if (elements.length) {
    const cards = featuredProductsCards()
    cards.init()
  }

  showVatPrices(FEATURED_PRODUCTS_PRICE_SELECTOR)
}

const setupIntakeFormBlocks = () => {
  const forms = document.querySelectorAll(`${INTAKE_FORM_SELECTOR} form`) || []

  forms.forEach((form: HTMLFormElement) => {
    const itb = intakeFormBlock()
    itb.init(form)
  })
}

const setupEmailLeadsFormBlocks = () => {
  const forms =
    document.querySelectorAll(`${EMAIL_LEADS_FORM_SELECTOR} form`) || []

  forms.forEach((form: HTMLFormElement) => {
    const itb = emailLeadsFormBlock()
    itb.init(form)
  })
}

const applyCouponCodeToPricingBlocks = async (
  pricingBlocks: PricingBlockComponentType[]
) => {
  const dataEl = document.getElementById(FEDORA_DATA_SELECTOR)
  const queryString = window.location.search.substr(1)
  const queryParams = queryStringToObject(queryString)
  const courseId = dataEl ? dataEl.dataset.courseId : null

  if (courseId && queryParams.coupon_code) {
    try {
      const response = await api(
        `/courses/${courseId}/coupon_and_product_data?${queryString}`
      )

      if (tGet(response, 'discounted_products')) {
        pricingBlocks.forEach((pb) => {
          pb.applyCouponCode(response)
        })
      }
    } catch {
      // there was an error fetching the coupon data
    }
  }
}

onReady(() => {
  init()

  window.document.addEventListener(
    'page-editor-v2:live-preview-updated',
    () => {
      // put any code here that should run after the live preview
      // from page editor v2 is updated
      init()
    }
  )
})
