import { iris } from '@teachable/iris'
import { get } from 'lodash'
import { emit } from 'src/util/iris-emit-common'
import { getIdentityEvent } from 'student/student/util/identity-analytics'

// This object will map any data attributes to their keys for event emission.
const STUDENT_SIDE_DATA_ATTRS = {
  'data-ss-event-name': 'name',
  'data-ss-event-href': 'href',
  'data-ss-event-type': 'type',
  'data-ss-course-id': 'courseId',
  'data-ss-lecture-id': 'lectureId',
  'data-ss-position': 'position',
  'data-ss-quiz-id': 'quizId',
  'data-ss-school-id': 'schoolId',
  'data-ss-user-id': 'userId',
  'data-ss-upsell-id': 'upsellId',
  'data-ss-friendly-url': 'friendlyUrl',
}

let shouldSendAnalytics = false

const initAnalytics = () => {
  // Initialize Iris library for student side tracking events.
  try {
    const irisUrlTag = document.getElementById('iris-url')
    const irisUrl = irisUrlTag?.getAttribute('data-iris-url') || ''

    iris.init({
      source: 'fedora-student',
      url: irisUrl,
      noun: 'user',
      noun_id: '',
      selector: '',
    })

    const blocksEl = document.getElementById('blocks')
    const schoolId = blocksEl?.getAttribute('data-ss-school-id') || ''

    const eventMetadata = {
      path: window.location.pathname,
      url: window.location.href,
      school_id: schoolId,
    }
    // Initialize Identity events needed for new authentication flow roll out
    const identityEvent = getIdentityEvent()
    if (identityEvent) {
      eventMetadata['name'] = identityEvent
    }
    emit('page_loaded', eventMetadata)
  } catch {
    console.error('Iris is not supported')
  }
}

// Pass in an send the data directly through to the analytics library.
// This requires the keys to correctly respect the interface provided
// by the values in the STUDENT_SIDE_DATA_ATTRS object.
export const emitStudentSideData = (metaData) => {
  if (!shouldSendAnalytics) {
    return
  }

  emit('clicked', metaData)
}

// Pass in an HTML element and gather data from data attributes.
export const emitStudentSideEvent = (event: string, element: HTMLElement) => {
  if (!shouldSendAnalytics) {
    return
  }

  const metaData = {}

  // Loop through attribute map and build metadata based on what's available
  // on the element being targeted in the DOM.
  Object.entries(STUDENT_SIDE_DATA_ATTRS).forEach(([k, v]) => {
    const dataAttribute = element.getAttribute(k)

    if (dataAttribute) {
      metaData[v] = dataAttribute
    }
  })

  emit(event, metaData)
}

const addEventListeners = () => {
  const trackableElements: NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-ss-event-name]'
  )

  for (const element of Array.from(trackableElements)) {
    if (get(element, 'dataset.ssLoadEvent', false)) {
      emitStudentSideEvent('loaded', element)
    } else {
      element.addEventListener('click', (e) => {
        emitStudentSideEvent('clicked', element)
      })
    }
  }
}

// Do not initialize analytics or allow for asynchronous event sending
// unless the iris-url is present.
document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('iris-url') !== null) {
    shouldSendAnalytics = true
  }

  if (!shouldSendAnalytics) {
    return
  }

  initAnalytics()
  addEventListeners()
})
