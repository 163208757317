interface AppConfigType {
  config: {
    [key: string]: any
  }
  csrf_token: string
  school: any
  user: any
}

const APP_CONFIG: AppConfigType = window && window.APP_CONFIG

export default APP_CONFIG
