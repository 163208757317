/**
 * @exports {Function}
 * @file Contains the onReady function.
 */

const EVENT_SIGNATURES = {
  DOM_CONTENT_LOADED: 'DOMContentLoaded',
  LECTURE_CHANGE: 'lecture:change',
  PAGE_CHANGE: 'page:change',
}

let isInitialPageLoadDone = false

/**
 * Pass this function a callback and it will execute once when the DOM is ready
 * and once each time a "page:change" event is broadcasted. The "page:change"
 * event only applies to the student-side.
 *
 * @function
 * @param {Function} callback
 */
export default function onReady(callback) {
  if (!callback || typeof callback !== 'function') {
    return
  }

  document.addEventListener('page:change', () => {
    if (!isInitialPageLoadDone) {
      return
    }

    callback()
  })

  document.addEventListener('lecture:change', () => {
    if (!isInitialPageLoadDone) {
      return
    }

    callback()
  })

  if (
    document.readyState !== 'complete' &&
    document.readyState !== 'interactive'
  ) {
    document.addEventListener(EVENT_SIGNATURES.DOM_CONTENT_LOADED, () => {
      callback()

      isInitialPageLoadDone = true
    })
  } else {
    callback()

    isInitialPageLoadDone = true
  }
}
