import { emit } from 'src/util/iris-emit-common'

const events = {
  teachable_login_page: '/teachable_accounts/sign_in',
  school_dashboard_page: '^/$',
  school_admin_dashboard_page: '/admin',
  device_confirmation_page: '/teachable_accounts/sessions/confirmation_code',
}

// We only want to send page_loaded events on the school dashboard when a user is logged in
const isLoggedIn = () => {
  const user = (window as any).currentUser()
  return user['id']
}

export const getIdentityEvent = () => {
  for (const key in events) {
    const regex = new RegExp(events[key])
    const pathMatch = regex.test(window.location.pathname)
    if (pathMatch) {
      if (key === 'school_dashboard_page') {
        if (!isLoggedIn()) {
          return false
        }
        // Since we receive an exponential amount of page loaded events on the student side (fedora-student)
        // We automatically block these events from routing to MixPanel
        // In order to track users landing on the school dashboard after login/signup, we emit a custom verb
        const blocksEl = document.getElementById('blocks')
        const schoolId = blocksEl?.getAttribute('data-ss-school-id') || ''

        const eventMetadata = {
          path: window.location.pathname,
          url: window.location.href,
          school_id: schoolId,
        }
        emit('school_dashboard_page_loaded', eventMetadata)
      }
      return key
    }
  }
  return false
}
