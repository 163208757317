import * as Sentry from '@sentry/browser'

// todo: remove use of Logger since sentry is embedded in mono-frontend student-checkout
export const Logger = {
  fatal(err: string | Error, metadata: Record<string, any> = {}) {
    Sentry.withScope((scope) => {
      scope.setExtras(metadata)
      if (typeof err === 'string') {
        Sentry.captureMessage(err, Sentry.Severity.Fatal)
      } else {
        Sentry.captureException(err)
      }
    })
  },
  error(err: string | Error, metadata: Record<string, any> = {}) {
    Sentry.withScope((scope) => {
      scope.setExtras(metadata)
      if (typeof err === 'string') {
        Sentry.captureMessage(err, Sentry.Severity.Error)
      } else {
        Sentry.captureException(err)
      }
    })
  },
  info(msg: string, metadata: Record<string, any> = {}) {
    Sentry.withScope((scope) => {
      scope.setExtras(metadata)
      Sentry.captureMessage(msg, Sentry.Severity.Info)
    })
  },
  setTags(tags: Record<string, string | number | undefined>) {
    Sentry.configureScope((scope) => {
      Object.entries(tags).forEach(([k, v]) => scope.setTag(k, String(v)))
    })
  },
  addBreadcrumb(message, breadcrumb: Omit<Sentry.Breadcrumb, 'message'> = {}) {
    Sentry.addBreadcrumb({
      level: Sentry.Severity.Info,
      ...breadcrumb,
      message,
      category: breadcrumb.category
        ? `log:${breadcrumb.category}`
        : 'log:generic',
    })
  },
  catchHandler(err: Error) {
    Sentry.captureException(err)
  },
}
