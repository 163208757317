export function queryStringToObject(
  paramString: string
): { [key: string]: string } {
  const sanitized = paramString.split('?').join('')
  const splitParams = sanitized.split('&')
  const params = {}

  for (let i = 0; i < splitParams.length; i++) {
    const item = splitParams[i]
    const keyValueArray = item.split('=')

    params[keyValueArray[0]] = keyValueArray[1]
  }

  return params
}

export function objectToQueryString(params) {
  const keys = Object.keys(params)

  return keys.length
    ? `?${keys
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join('&')}`
    : ''
}
