import { Logger } from 'student/secure/checkout/sentry'

export const emailLeadsFormBlock = () => {
  const proto = {
    init(form: HTMLFormElement) {
      form.addEventListener('submit', () => this.submitEmailLeadsForm(form))
      this.submitEmailLeadsForm = this.submitEmailLeadsForm.bind(this)
    },

    async submitEmailLeadsForm(form: HTMLFormElement) {
      form.disable = true

      try {
        const source = form.getAttribute('data-source')
        const school_id = form.getAttribute('data-ss-school-id')

        const tokenTag = document.getElementsByName('csrf-token')[0]
        const csrfToken = tokenTag?.getAttribute('content')

        const redirect_path = location.pathname

        const emailEl: HTMLInputElement | null = form.querySelector(
          '[name=email]'
        )
        const checkboxEl: HTMLInputElement | null = form.querySelector(
          '[name=consent]'
        )

        // When checkbox is present, user cannot submit unless it is checked
        if (emailEl && emailEl.value) {
          const apiEndpoint = `/lead`
          await fetch(apiEndpoint, {
            method: 'POST',
            headers: {
              'X-CSRF-Token': csrfToken,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: emailEl.value,
              source,
              school_id,
              redirect_path,
              initial_consent: checkboxEl || false,
            }),
          })
        }

        const blockId = form.getAttribute('data-block-id')
        const msgEl: HTMLElement | null = document.querySelector(
          `#status-${blockId}`
        )

        if (msgEl) msgEl.hidden = false
        form.hidden = true
      } catch (error) {
        const errorEl = form.querySelector('.block__email_leads__error')
        if (errorEl) {
          errorEl.innerHTML = 'Something went wrong. Please try again later.'
        }
        Logger.error(error)
      }

      form.disable = false
    },
  }

  return Object.create(proto)
}
