import { get } from 'lodash'

// get server side data passed through data attributes

export function getCurrentUser() {
  const dataMetaTag = document.getElementById('fedora-data')
  return get(dataMetaTag, 'dataset', {})
}

export function getFedoraKeys() {
  const keysMetaTag = document.getElementById('fedora-keys')
  return get(keysMetaTag, 'dataset', {})
}
