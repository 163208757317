import { get } from 'lodash'
import APP_CONFIG from 'src/common/app-config'
import { getCsrfToken } from 'src/util/csrf'

interface TError extends Error {
  response?: Response
}

export const api = async (
  url: string,
  options?: RequestInit
): Promise<Response> => {
  const fetchOptions = getFetchOptions(options)
  const response = await window.fetch(url, fetchOptions)

  handleErrors(response)

  return response
}

export const jsonApi = async <T>(
  url: string,
  options: IRequestInit = {}
): Promise<T> => {
  if (options.body && typeof options.body !== 'string') {
    options.body = JSON.stringify(options.body)
  }

  const headers = getHeaders(options.headers)
  headers.set('Content-Type', 'application/json')
  options.headers = headers

  const response = await api(url, options as RequestInit)
  const clone = response.clone()

  try {
    return await response.json()
  } catch {
    return (await clone.text()) as any
  }
}

export function mockJsonApi(mockJsonBody, mockTiming = 300) {
  return function jsonApi(url: string, options: IRequestInit = {}) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockJsonResponse(mockJsonBody).json())
      }, mockTiming)
    })
  }

  function mockJsonResponse(json) {
    return new Response(JSON.stringify(json), {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
      }),
    })
  }
}

function getHeaders(headers?: Headers | { [key: string]: any }): Headers {
  const hasNoHeaders = !headers
  const isNotContructedProperly = headers && !(headers instanceof Headers)

  if (hasNoHeaders || isNotContructedProperly) {
    return new Headers(headers || {})
  }

  return headers as Headers
}

// Only exported for testing, don't use this function anywhere.
export function getFetchOptions(options: IRequestInit = {}): RequestInit {
  const defaults = { method: 'GET', credentials: 'same-origin' } as IRequestInit
  const headers = getHeaders(options.headers)

  // Always pass up the csrf_token if it's available.
  const csrfToken =
    (headers.get('No-CSRF') !== 'true' &&
      !!APP_CONFIG &&
      get(APP_CONFIG, 'csrf_token')) ||
    (headers.get('No-CSRF') !== 'true' && getCsrfToken())

  if (csrfToken) {
    headers.set('X-CSRF-Token', csrfToken)
  } else {
    headers.delete('No-CSRF')
  }

  options.headers = headers

  return { ...defaults, ...options } as RequestInit
}

function handleErrors(response: Response): Response {
  if (response.ok) {
    return response
  }

  const error = new Error(response.statusText) as TError

  error.response = response

  throw error
}
