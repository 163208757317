import { entries, get } from 'lodash'
import { getCurrentUser } from 'student/shared/util/dataset-data'

const MAX_PERCENT_COMPLETE = 100

export const PRICE_SELECTOR = '.course-card__price'
export const HIDDEN_CLASS = 'hidden'
export const PROGRESS_BAR_FILL_SELECTOR = '.course-card__progressbar-fill'
export const SUBTITLE_SELECTOR = '.course-card__content__subtitle'

export const courseCards = () => {
  const proto = {
    init() {
      this.setProgress()
      this.setProgressBar()
    },

    getCard(courseId: number) {
      return document.querySelector(
        `.course-card[data-course-id="${courseId}"]`
      )
    },

    hidePricing(card: HTMLElement) {
      const prices = card.querySelectorAll(PRICE_SELECTOR)

      if (prices) {
        Array.from(prices).forEach((p) => {
          p.classList.add(HIDDEN_CLASS)
          p.setAttribute('aria-hidden', 'true')
        })
      }
    },

    hideSubtitles(card: Element) {
      const subtitles = card.querySelectorAll(SUBTITLE_SELECTOR)

      if (subtitles) {
        Array.from(subtitles).forEach((s) => {
          s.classList.add(HIDDEN_CLASS)
          s.setAttribute('aria-hidden', 'true')
        })
      }
    },

    setPercentText(percent: number, node: Element, card: Element) {
      if (node) {
        node.textContent = `${percent}%`
        if (node.parentElement) {
          node.parentElement.classList.remove(HIDDEN_CLASS)
          node.parentElement.setAttribute('aria-hidden', 'false')
        }
      }

      this.hidePricing(card)
    },

    setPercentFrom(reportCard, courseId: number) {
      const card = this.getCard(courseId)
      if (!card) {
        return
      }

      const node = card.querySelector(`#percent-complete-${courseId}`)
      const percent = this.getPercentRounded(
        get(reportCard, 'percent_complete', 0)
      )

      if (get(reportCard, 'percent_complete') && percent) {
        this.setPercentText(percent, node, card)
        this.setProgressBar(card, percent)
      }
    },

    getPercentRounded(percentComplete: number) {
      return percentComplete > MAX_PERCENT_COMPLETE
        ? MAX_PERCENT_COMPLETE
        : percentComplete
    },

    setProgress() {
      const user = getCurrentUser()
      const reportCard = get(user, 'reportCard', null)

      reportCard &&
        entries(JSON.parse(reportCard)).forEach(([courseId, userReportCard]) =>
          this.setPercentFrom(userReportCard, courseId)
        )
    },

    setProgressFill(node: Element, percent: string) {
      if (!node) {
        return
      }

      ;(node as HTMLElement).style.width = `${percent}%`
      node.setAttribute('aria-valuenow', `${percent}%`)

      if (node.parentElement) {
        node.parentElement.classList.remove(HIDDEN_CLASS)
        node.parentElement.setAttribute('aria-hidden', 'false')
      }
    },

    setProgressBar(card: HTMLElement, percent: string) {
      if (!card) {
        return
      }

      const fills = card.querySelectorAll(PROGRESS_BAR_FILL_SELECTOR)

      if (fills) {
        Array.from(fills).forEach((fill) =>
          this.setProgressFill(fill, this.getPercentRounded(percent))
        )

        this.hideSubtitles(card)
      }
    },
  }

  return proto
}
